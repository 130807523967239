import { Box, ChakraProvider, Spinner } from '@chakra-ui/react';
import { css, Global as CSSGlobal } from '@emotion/react';
import { ModalProvider } from '@florencecard-components/modal';
import { PortalProvider } from '@florencecard-components/portal';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import GlobalErrorBoundary from '~/components/GlobalErrorBoundary';
import MainHeader from '~/components/MainHeader';
import { AuthProvider, useUser } from '~/core/auth';
import { FirebaseProvider } from '~/core/firebase';
import { DEFAULT_ROUTE, Routes } from '~/routes';

const globalCss = css`
  // XXX: iOS Safari에서 폰트 사이즈가 16px보다 작으면 화면확대가 일어남.
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    select,
    textarea,
    input {
      font-size: 16px !important;
    }
  }
`;

const mainHeaderNotRenderedPathnames: string[] = [Routes.로그인, Routes['청첩장 수정']];

function RouteGuard({ children }: { children: ReactNode }) {
  const router = useRouter();
  const user = useUser();

  useEffect(() => {
    const pathname = router.pathname;

    if (user === null && pathname !== Routes.로그인) {
      router.replace(Routes.로그인);
    } else if (user !== null && pathname === Routes.로그인) {
      router.replace(DEFAULT_ROUTE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return (
    <Box>
      {!mainHeaderNotRenderedPathnames.includes(router.pathname) && user != null ? (
        <MainHeader />
      ) : null}
      {children}
    </Box>
  );
}

export default function AdminApp({ Component, pageProps }: AppProps) {
  const [initialized, setInitialized] = useState(false);
  const handleInitialize = useCallback(() => {
    setInitialized(true);
  }, []);

  return (
    <>
      <Head>
        <title>플로렌스카드 어드민</title>
      </Head>
      <GlobalErrorBoundary>
        <ChakraProvider>
          <PortalProvider>
            <ModalProvider>
              <FirebaseProvider>
                <AuthProvider onInitialize={handleInitialize}>
                  <CSSGlobal styles={globalCss} />
                  {initialized ? (
                    <RouteGuard>
                      <Box as="main">
                        <Component {...pageProps} />
                      </Box>
                    </RouteGuard>
                  ) : (
                    <Box display="flex" alignItems="center" justifyContent="center" p={10}>
                      <Spinner size="lg" />
                    </Box>
                  )}
                </AuthProvider>
              </FirebaseProvider>
            </ModalProvider>
          </PortalProvider>
        </ChakraProvider>
      </GlobalErrorBoundary>
    </>
  );
}
