import { createIcon } from '@chakra-ui/react';
import React from 'react';

const MenuIcon = createIcon({
  displayName: 'MenuIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
    </>
  ),
});

export default MenuIcon;
