import {
  Box,
  Button,
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  IconButton,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef } from 'react';
import MenuIcon from '~/components/MenuIcon';
import { useLogoutDialog } from '~/core/auth';
import { getRouteName, Routes } from '~/routes';
import { zIndexes } from '~/styles/z-index';

const routes = [Routes.대시보드, Routes['청첩장 관리'], Routes['기업·제휴회원 관리']];

export default function MainHeader() {
  const router = useRouter();
  const [openLogoutDialog, closeLogoutDialog] = useLogoutDialog();

  const openMenuButtonRef = useRef<HTMLButtonElement>(null);
  const { isOpen: isMenuOpen, onOpen: openMenu, onClose: closeMenu } = useDisclosure();

  const navigate = useCallback(
    (route: Routes) => {
      router.push(route);
      closeMenu();
    },
    [router, closeMenu],
  );

  const isLocationMatch = useCallback(
    (pathname: string) => {
      return router.pathname === pathname;
    },
    [router.pathname],
  );

  const logout = useCallback(() => {
    closeMenu();
    openLogoutDialog?.();
  }, [closeMenu, openLogoutDialog]);

  const refresh = useCallback(() => {
    router.reload();
  }, [router]);

  useEffect(
    () => () => {
      closeLogoutDialog?.();
    },
    [closeLogoutDialog],
  );

  return (
    <Box
      as="header"
      display="flex"
      alignItems="center"
      justifyContent={{
        base: 'space-between',
        sm: 'flex-start',
      }}
      position="sticky"
      left={0}
      right={0}
      top={0}
      px={6}
      py={2}
      height="60px"
      borderBottomWidth="1px"
      borderBottomColor="grey.400"
      backgroundColor="white"
      zIndex={zIndexes.header}
    >
      <Heading role="link" size="md" onClick={refresh} cursor="pointer">
        플로렌스카드 어드민
      </Heading>
      <Box
        as="nav"
        px={4}
        display={{ base: 'none', sm: 'flex' }}
        flex="1 1 auto"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack isInline={true} spacing={4}>
          {routes.map((route) => (
            <Box key={route}>
              <Button
                role="link"
                variant="link"
                textDecoration={isLocationMatch(route) ? 'underline' : undefined}
                onClick={() => navigate(route)}
              >
                {getRouteName(route)}
              </Button>
            </Box>
          ))}
        </Stack>
        <Box>
          <Button variant="link" colorScheme="purple" onClick={logout}>
            로그아웃
          </Button>
        </Box>
      </Box>
      <Box display={{ base: 'flex', sm: 'none' }}>
        <IconButton
          ref={openMenuButtonRef}
          aria-label="메뉴 열기"
          icon={<MenuIcon />}
          variant="ghost"
          onClick={openMenu}
        />
      </Box>
      <Drawer
        isOpen={isMenuOpen}
        onClose={closeMenu}
        placement="top"
        returnFocusOnClose={true}
        finalFocusRef={openMenuButtonRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderBottomWidth="1px"
          >
            <Heading size="md">플로렌스카드 어드민</Heading>
            <CloseButton aria-label="메뉴 닫기" onClick={closeMenu} />
          </DrawerHeader>
          <DrawerBody as="ul" display="block" px={0} py={2} m="0">
            {routes.map((route) => (
              <Box key={route} as="li" px={6} py={3} css={listStyle}>
                <Button
                  role="link"
                  size="md"
                  variant="link"
                  onClick={() => navigate(route)}
                  justifyContent="flex-start"
                  textAlign="left"
                  textDecoration={isLocationMatch(route) ? 'underline' : undefined}
                  isFullWidth={true}
                >
                  {getRouteName(route)}
                </Button>
              </Box>
            ))}
            <Box as="li" px={6} py={3} css={listStyle}>
              <Button
                variant="link"
                size="md"
                colorScheme="purple"
                isFullWidth={true}
                justifyContent="flex-start"
                textAlign="left"
                onClick={logout}
              >
                로그아웃
              </Button>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

const listStyle = css`
  list-style: none;
`;
