import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import Router from 'next/router';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { of, Subject } from 'rxjs';
import { catchError, delay, take, timeout } from 'rxjs/operators';
import { useFirebase } from '~/core/firebase';
import { Routes } from '~/routes';

interface UserInfo {
  displayName: string | null;
  email: string | null;
  phoneNumber: string | null;
  photoURL: string | null;
  providerId: string;
  /**
   * The user's unique ID.
   */
  uid: string;
}

export interface AuthContextValue {
  user: UserInfo | null;
  logoutDialogOpened?: boolean;
  openLogoutDialog?(): void;
  closeLogoutDialog?(): void;
}

const AuthContext = createContext<AuthContextValue>({
  user: null,
});
AuthContext.displayName = 'AuthContext';

export function useUser() {
  const { user } = useContext(AuthContext);

  return user;
}

export function useLogoutDialog() {
  const { openLogoutDialog, closeLogoutDialog } = useContext(AuthContext);

  return [openLogoutDialog, closeLogoutDialog] as const;
}

interface Props {
  onInitialize?(user: UserInfo | null): void;
  initializeTimeoutDue?: number;
  children: ReactNode;
}

export function AuthProvider({ onInitialize, initializeTimeoutDue = 5_000, children }: Props) {
  const firebase = useFirebase();
  const [user, setUser] = useState<UserInfo | null>(firebase?.auth?.()?.currentUser ?? null);
  const user$ = useMemo(() => new Subject<UserInfo | null>(), []);

  const cancelRef = useRef<HTMLButtonElement>(null);
  const [logoutDialogOpened, setLogoutDialogOpened] = useState(false);
  const openLogoutDialog = useCallback(() => {
    setLogoutDialogOpened(true);
  }, []);

  const closeLogoutDialog = useCallback(() => {
    setLogoutDialogOpened(false);
  }, []);

  const [logoutRequesting, setLogoutRequesting] = useState(false);
  const logout = useCallback(async () => {
    if (firebase == null) {
      return;
    }

    const auth = firebase.auth();

    if (auth.currentUser != null) {
      try {
        await auth.signOut();
        closeLogoutDialog();
        Router.replace(Routes.로그인);
      } catch {
        alert('로그아웃 오류 발생');
      } finally {
        setLogoutRequesting(false);
      }
    }
  }, [firebase, closeLogoutDialog]);

  useEffect(() => {
    const subscription = user$
      .pipe(
        timeout(initializeTimeoutDue),
        take(1),
        delay(0),
        catchError(() => of(null)),
      )
      .subscribe((user) => {
        onInitialize?.(user);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [user$, onInitialize, initializeTimeoutDue]);

  useEffect(() => {
    if (firebase == null) {
      return;
    }

    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      user$.next(user);
    });

    return () => {
      unsubscribe();
    };
  }, [firebase, user$]);

  return (
    <AuthContext.Provider value={{ user, logoutDialogOpened, openLogoutDialog, closeLogoutDialog }}>
      {children}
      <AlertDialog
        isOpen={logoutDialogOpened}
        leastDestructiveRef={cancelRef}
        onClose={closeLogoutDialog}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>로그아웃</AlertDialogHeader>
          <AlertDialogBody>로그아웃 하시겠습니까?</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={closeLogoutDialog}>
              아니오
            </Button>
            <Button colorScheme="purple" onClick={logout} ml={3} isLoading={logoutRequesting}>
              예
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </AuthContext.Provider>
  );
}
