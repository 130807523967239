import { StringKeyOf } from '@florencecard-lib/type-utils';

export interface Option<V extends string = string> {
  name: string;
  value: V;
}

type EnumLike = Record<string, string>;
type EnumOption<T extends EnumLike> = Option<T[StringKeyOf<T>]>;

export const 전체 = '__ALL__';

type AllOption = Option<typeof 전체>;

export type WithAllOptionValue<T> = T | typeof 전체;

export function objectToOptions<T extends EnumLike>(value: T): Array<EnumOption<T>> {
  return Object.entries(value).map(([name, value]) => ({
    name,
    value: value as T[StringKeyOf<T>],
  }));
}

export function objectToOptionsWithAll<T extends EnumLike>(value: T) {
  const options = objectToOptions(value) as Array<EnumOption<T> | AllOption>;

  options.unshift({
    name: '전체',
    value: 전체,
  });

  return options;
}
