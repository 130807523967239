import { objectToOptions } from '~/models/option';

export enum Routes {
  대시보드 = '/dashboard',
  '청첩장 관리' = '/letters',
  로그인 = '/login',
  '청첩장 수정' = '/letter-edit',
  '기업·제휴회원 관리' = '/enterprise',
}

const routeOptions = objectToOptions(Routes);

export function getRouteName(route: Routes) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return routeOptions.find((x) => x.value === route)!.name;
}

export const DEFAULT_ROUTE = Routes.대시보드;
